<template>
    <section>
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center">Paiement envoyé avec succès</h1>
                    <picture>
                        <source class="card-img-top"  srcset="@/assets/images/import/online_payments.webp" type="image/webp">
                        <source class="card-img-top"  srcset="@/assets/images/import/online_payments.png" type="image/png">
                        <img src="@/assets/images/import/online_payments.png" alt="">
                    </picture>
                </div>
                <div class="col-md-12">
                    <button class="btn-block btn-primary btn" @click="home()">Revenir sur la page d'accueil</button>
                </div>
            </div>
        </div> 
    </section>
</template>
<script>
export default {
    name: "payment-succes",
    methods: {
        home() {
            this.$router.push ({
                name:"home"
            })
        }
    }
}
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}
</style>